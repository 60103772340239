@import 'src/styles/colors.scss';

.bulk-export-container {
    display: flex;
    justify-content: space-between;
    padding: 12px 30px 12px 0px;

    &::after {
        position: absolute;
        bottom: 90px;
        left: 0;
        content: " ";
        display: block;
        border-top: 1px solid $grey;
        width: 270px;
    }
}
