@import 'src/styles/colors.scss';

.title-container {
    display: flex;
    justify-content: space-between;
    color: $white;
    margin-left: 20px;

    h2 {
        font-size: 34px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.title-buttons {
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-left: 10px;
}