
.instructions-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .instruction {
        padding: 16px;
    }
}
