.company-options-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;
    padding: 20px;
    position: relative;
    width: 100%;

    .header {
        text-align: center;
        margin-bottom: 20px;
        font-weight: bold;
    }
    
    .tabs-section {
        margin-bottom: 20px;
    }

    .toggle-section {
        display: flex;
        flex-direction: column;
        gap: 15px;
        overflow: hidden;
        padding-left: 11px;
    }
    
    .close-button {
        position: absolute;
        top: 10px;
        right: 64px;
    }

    .company-options-loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        padding-bottom: 50px;
    }

    table {
        width: 100%;
        th, td {
            padding: 10px;
            text-align: center;
        }
    }

    .save-button-container {
        margin-top: 20px;
        text-align: center;
    }
}
