@import 'src/styles/colors.scss';

.create-button-container {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.generic-return-data-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: #f4f7fb;
    border: 1px solid $grey;
    border-radius: 2px;
    min-height: 200px;
    cursor: pointer;
    margin-top: 12px;

    .generic-return-data-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        p {
            margin: 12px 0 0;
            text-align: center;
        }
    }
}