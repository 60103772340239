@import 'src/styles/colors.scss';

.allocator-container {
    background-color: $allocatorComponentBackgroundLightColor;
    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: 100%;

    .allocator-left-container {
        min-width: 270px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 12px 0 0 20px;
        box-sizing: border-box;
        background-color: $primaryColor;

        &::after {
            position: absolute;
            top: 0;
            left: 270px;
            content: " ";
            display: block;
            border-right: 1px solid $grey;
            height: calc(100% - 28px);
        }

        .allocator-dropdown-container {
            display: flex;
            margin: 10px 15px 4px 0;
        }

        .allocator-left-container-upload {
            width: 220px;
            margin: 8px 0;
        }

        .allocator-left-container-list {
            overflow-y: auto;
            display: flex;
            flex-direction: column;
        }
    }

    .allocator-right-container {
        width: 100%;
        max-width: calc(100% - 270px);
        padding-top: 32px;
        padding-bottom: 12px;
        background-color: $primaryColor;

        .reports-container {
            background-color: $white;
            padding-left: 20px;
            padding-top: 5px;
            height: 100%;
        }
    }
}

.MuiButtonBase-root.MuiMenuItem-root {
    background-color: $white;
    min-height: 36px;
    min-width: 120px;
    @media (min-width: 600px) {
        min-height: 36px;
    }
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
    background-color: $allocatorComponentBackgroundColor;
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-disabled {
    background-color: $white;
}

.MuiButtonBase-root.MuiMenuItem-root:hover {
    background-color: $allocatorComponentBackgroundLightColor;
}