.application-nav-bar-container{
    align-items: center;
    display: flex;
    width: 100vw;
    z-index: 100;
}

.application-nav-bar-left-container {
    align-items: center;
    display: flex;
}

.application-nav-bar-right-container {
    align-items: center;
    display: flex;

    .icon-container {
        display: flex;
        width: 50px;
        flex-direction: row;
        align-items: center;
        .icon {
            height: 24px;
            margin-left: 5px;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

.product-logo-container {
    height: 32px;
}

.stratus-logo {
    height: 32px;
    width: 32px;
    cursor: pointer;
}