.data-table-container {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;

    .grid-container {
        position: relative;
        height: 100%;
        width: calc(100% - 50px); // 50px is the width of the right toolbar
    }

    .grid-right-toolbar-container {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 20;
        width: 50px;
        height: 100%;
        background-color: #354560;
        border-left: 1px solid #e0e0e0;
    }

    .data-table-select {
        width: 100%;
    }

    .data-table-select fieldset {
        border: none;
        padding: 0;
    }
    .MuiOutlinedInput-input {
        padding-left: 0;
        font-size: 14px;
    }
    .sbp-jurisdiction-select {
        font-weight: 600;
        margin: 10px 0px;
        display: inline-flex;
        margin-left: auto;
        & .MuiOutlinedInput-input {
            padding: 0px 35px 0px 10px;
        }
    }
    .grid-toolbar-container {
        flex-direction: column;
        align-items: flex-start;
        .first-grid-toolbar-row {
            display: flex;
            width: 100%;
        }
    }

    .select-all-rows-button-container {
        left: 1px;
        z-index: 1000;
        width: 20px;
        height: 40px;
        cursor: crosshair;
        background-color: transparent;
        position: absolute;

        .select-all-rows-button {
            width: 20px;
            height: 20px;
            background-color: #000;
            clip-path: polygon(0 0, 100% 0, 0 100%);
        }
    }
}
